.projet-wrap{
  padding-top: 266px;
  display: flex;
}
.projet-picture-grid{
  padding-left:10px;
  width: 50%;
}

.projet-picture-grid-item.cover{
  width: 100%;
}

.projet-picture-grid-item{
  padding: 10px;
  width: 50%;
  float: left;
}


#lightbox{
  height: 100%;
  display: flex;
  align-items: center;
}

.lb-dataContainer{
  display: none !important;
}

.lightbox .lb-image{
  border: none !important;
  border-radius: 0px !important;
}
.lb-outerContainer{
  border-radius: 0px !important;
  background: transparent;
}


.projet-infos{
  padding-left: 30px;
  width: 50%;
  h4:first-of-type{
    padding-bottom: 20px;
  }
}

.video{
  padding: 50px 0 20px 0;
}

.projet-text{
  padding: 30px 0;
  font-size: 17px;
  line-height: 21px;
  p{
    margin-bottom: 21px;
  }
}

.infos-comp {
  padding-bottom: 30px;
  .caps{
    font-size: 12px;
    line-height: 20px;
    font-family: $titre;
  }

  font-size: 13px;
  line-height: 17px;
}


.agenda-projets{
  font-size: 17px;
  line-height: 21px;
  margin-bottom: 30px;
}

.upcoming{
  padding-bottom: 0 !important;
  margin-bottom: 15px;
}

.date{
  font-weight: bolder;
}

.past{
  margin-top: 30px;
  margin-bottom: 10px;
}

.agenda-item{
  margin-bottom: 10px;
}


.press{
  margin-bottom: 40px;
  font-size: 17px;
  line-height: 21px;
}

.projet-header-text{
  margin-top: 0.2em;
}