.heading{
  padding: 62px 20px 5px;
  min-height: 231px;
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 10px));
  grid-gap: 20px;
  margin-bottom: 50px;
}

.heading-img{
  max-height: 250px;
  overflow: hidden;
  position: relative;
  img{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    min-height: 250px;
    max-width: 100%;
    object-fit: cover;
  }
}

.picture-grid{
  width: 100%;
  padding: 0px 10px;
  display: flex;
  flex-wrap: wrap;
}

.picture-grid-item{
  padding: 10px;
  width: 25%;
}

.aspect-ratio{
  position: relative;
  padding-top: 100%;
  height: 0;
  background-color: whitesmoke;
  overflow: hidden;

  .img-container{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    img{
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-width: 100%;
      min-height: 100%;
      max-height: 100%;
      object-fit: cover;

    }
  }



  .img-overlay{
    padding: 0 10px;
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 9;
    transition: opacity 0.3s ease-in-out;
    h2{
      color: white;
    }
    .title{
      position: absolute;
      bottom: 7px;
    }

    .statut{
      position: absolute;
      top: 8px;
      right:8px;
      left:8px;
      text-align: right;
    }
  }
}

.creation .img-overlay{
  background-color: rgb(255,0,204);
}

.laboratoire .img-overlay{
  background-color: blue;
}

.appear{
  opacity:1 !important;
  transition: opacity 0.3s ease-in-out;
  pointer-events: auto !important;
}

.goto:hover{
  text-decoration: none;
}

.intro-text{
  p{
    margin-bottom: 28px;
  }
}

.captation{
  .img-overlay{
    opacity: 1 !important;
  }

  .title{
    color: black !important;
  }
}
.picture-grid-item.captation a{
  background-color: white;
  border: 2px solid black;
  flex: 1;
  text-decoration: none;
  display: block;
}