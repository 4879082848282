.carousel{
  width:100%;
  max-height: 100%;
}
.carousel-inner{
  width: 100%;
  margin: 0 auto;
}
.image-wrap{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.image-wrap img{
  //object-fit: contain;
  max-height: 100%;
  max-width: 100%;
  margin: 0 auto;
}
.carousel-control{
  width: 50%;
}

.carousel-control-prev{
  cursor: w-resize;
  width: 50% !important;
}

.carousel-control-next{
  cursor: e-resize;
}

.carousel .item.video{
}

.carousel-inner > .item > img {
  margin: 0 auto;
}


// FOR VIMEO VIDEO

.top {
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  position: relative;
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}