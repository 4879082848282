h4.laboratoire a{
 color: blue;
}

h4.creation a{
  color: rgb(255,0,204);
}

h4.creation a:hover{
  text-decoration: none;
}

h4.laboratoire a:hover{
  text-decoration: none;
}

.agenda-wrap{
  font-size: 17px;
  line-height: 21px;
  display: flex;
  justify-content: flex-start;
  padding: 2px 20px 0;
}

.agenda .past{
  margin-top: 0px;
}

.upcoming, .past{
  flex:1;
}

.agenda-wrap h4{
  margin-top: 20px;
}

.agenda-wrap .date{
  margin-top: 10px;
}

.agenda-item{
  .lieu, .dates{
    font-size: 17px;
    line-height: 21px;
  }
}

.agenda-dates-menu-item{
  a{
    color: cyan;
    font-size: 1.2em;
    position: relative;
    top: 0.1em;
  }
  padding-left: 20px;
  margin-bottom: 0.5em;
}

.agenda-dates{
  .picture-grid-item{
    h4{
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}

.agenda-dates main{
  padding-top: 11.8em;
}