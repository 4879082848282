.captationswrap{
  padding-left: 20px;
  padding-bottom: 30px;
}

.capt-title{
  font-size: 25px;
  margin-bottom: 30px;
}

.captationswrap{
  h3{
    margin-top: 30px;
    font-size: 21px !important;
    font-family: 'FjallaOne';
  }
}