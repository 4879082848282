// SM BREAKPOINT
@media (max-width: 768px) {

  .page {

    margin: 0 auto;
    min-width: 0px;

  }

  main {
    padding: 0;
    padding-top: 216px;
    overflow-y: hidden;
  }

  header {
    display: flex;
    position: fixed;
    height: auto;
    display: flex;
    width: 100%;
    padding: 20px;
    background-color: white;
    z-index: 99;
    justify-content: flex-end;
  }

  #logo {
    justify-self: flex-start;
    min-width: 140px;
    max-width: 140px;
  }

  .menu-wrap{
    align-self: flex-end;
    justify-content: flex-start;
    display: flex;
    height: 100%;
    flex:1;
  }

  .menu-list, .menu-infos {
    padding: 0;
    margin: 0;
    max-width: 150px;
  }

  .menu-infos{
    position: relative;
    top:-8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 20px;
  }

  .facebook, .instagram{
    margin-top: 10px;
    width: 20px;
    height: 20px;
  }


  .heading{
    padding: 0 20px;
  }

  .texte{
    padding-right: 20px;
  }

  .team-list{
    padding-right: 20px;
  }

  .projet-picture-grid{
    padding-right: 10px;
  }

  .picture-grid-item {
    width: 33.3333333333%;
    padding: 5px;
  }

  .picture-grid {
    padding: 0 15px;
  }

  .projet-wrap {
    padding-top: 0;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .projet-picture-grid{
    width: 100%;
  }

  .projet-picture-grid-item.cover{
    width: 50%;
  }

  .projet-infos{
    width: 100%;
    padding: 0 20px;
  }

  .menu-xs{
    display: block;
  }

  .menu-list{
    display: none;
  }

  #open{
    display: block;
  }


}



@media only screen
and (max-device-width: 600px)
{



}