// TYPOGRAPHY

@font-face {
  font-family: 'FjallaOne';
  src: url('../fonts/FjallaOne-Regular.woff') format('woff'),
  url('../fonts/FjallaOne-Regular.eot') format('eot'),
  url('../fonts/FjallaOne-Regular.svg') format('svg'),
  url('../fonts/FjallaOne-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansCondensed-Light';
  src: url('../fonts/OpenSansCondensed-Light.woff') format('woff'),
  url('../fonts/OpenSansCondensed-Light.eot') format('eot'),
  url('../fonts/OpenSansCondensed-Light.svg') format('svg'),
  url('../fonts/OpenSansCondensed-Light.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSansCondensed-Bold';
  src: url('../fonts/OpenSansCondensed-Bold.woff') format('woff'),
  url('../fonts/OpenSansCondensed-Bold.eot') format('eot'),
  url('../fonts/OpenSansCondensed-Bold.svg') format('svg'),
  url('../fonts/OpenSansCondensed-Bold.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}


// ====== FONTS FAMILIES ====== //


$labeur:        'OpenSansCondensed-Light';
$labeur-bold:   'OpenSansCondensed-Bold';
$labeur-italic:  'Arial', sans-serif;
$titre:         'FjallaOne', sans-serif;
$titreitalic:   'Arial', sans-serif;
$notes:         'Arial', sans-serif;
$notesitalic:   'Arial', sans-serif;


.caps{
  text-transform: uppercase;
}

.magenta{
  color:rgb(255,0,204);
}
.blue{
  color: blue;
}
.cyan{
  color:cyan;
}

body{
  font-family: $labeur;
  font-size: 23px;
  line-height: 28px;
}

h1,h2{
  font-size: 20px;
  font-weight: normal;
  font-family: $labeur-bold;
  text-transform: uppercase;
}

.notes-bold{
  font-size: 12px;
  font-family: $titre;
  line-height: 15px;
  font-weight: bolder;
}

.date{
  font-size: 12px;
  font-family: $titre;
  line-height: 15px;
  font-weight: bolder;
}

h1{
  letter-spacing: 4px;
  margin-bottom: 10px;
}

h3{
  font-size: 22px;
  font-family: $labeur-bold;
  padding-bottom: 5px;
}

h4{
  font-size: 16px;
  font-family: $titre;
}

h5{
  font-size: 14px;
  font-family: $titre;
  line-height: 21px;
}

@media (max-width: 768px) {

   header h1,h2{
    font-size: 12px;
    font-weight: normal;
    font-family: $labeur-bold;
    text-transform: uppercase;
  }


  .notes-bold{
    font-size: 9px;
    font-family: $titre;
    line-height: 15px;
    font-weight: normal;
  }


}


// ===== MEDIUM DEVICES ===== //

@media (min-width:769px) and (max-width: 1024px) {

  // ===== TITRE ===== //
  $titre-fs: 4.5vw;
  $titre-ln: 4.5vw;

  // ===== TEXTE COURANT ===== //
  $labeur-fs: 0.4vw;
  $labeur-ln: 2.2vw;
  $labeur-ti: 2.7777777777777777vw;

  // ===== NOTES ===== //
  $note-fs: 0.9027777777777777vw;
  $note-ln: 1.1805555555555556vw;
  $note-ti: 1.0416666666666665vw;


}

// ===== LARGE DEVICES ===== //

@media (min-width:1024px){

  // ===== TITRE ===== //
  $titre-fs: 4.5vw;
  $titre-ln: 4.5vw;

  // ===== TEXTE COURANT ===== //
  $labeur-fs: 0.4vw;
  $labeur-ln: 2.2vw;
  $labeur-ti: 2.7777777777777777vw;

  // ===== NOTES ===== //
  $note-fs: 0.9027777777777777vw;
  $note-ln: 1.1805555555555556vw;
  $note-ti: 1.0416666666666665vw;

}