.heading.slideshow{
  padding: 40px 20px 0px;
  width: 100% !important;
  max-width: none;
  height: 250px;
  overflow: hidden;
}

.texte{
  margin-top: 23px;
  padding-left: 20px;
p{
  margin-bottom: 25px;
}
}

#la-cavale{
  .texte{
    margin-top: 23px;
    font-size: 20px;
    line-height: 25px;
  }
  .texte a{
    display: inline-block;
    color: rgb(123,247,32);
  }
}

.team-list{
  padding-left: 20px;
  padding-top-top: 20px;
}

.team-list-item{
  padding: 30px 0 15px 0;
  display: flex;

  h1{
    color: rgb(122,247,30);
    position: relative;
    top:-5px;
  }
}

.picture{
  flex: 1;
  background-color: blue;
  align-self: center;
}

.bio{
  flex:3;
  padding-left: 50px;
  font-size: 17px;
  line-height: 21px;

}


