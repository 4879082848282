#logo{
  width: 100%;
}

#logo img{
  width: 100%;
}

.menu-list{
  padding-top: 30px;
  margin: 0;
}

.menu-list-item{
  padding: 5px 0;
}

.menu-list-item:hover{
  text-decoration: none;
}

.menu-list-item#la-cavale:hover{
  color: rgb(122,247,30);
}
.menu-list-item#creations:hover{
  color: rgb(255,0,204);
}
.menu-list-item#laboratoire:hover{
  color: blue;
}
.menu-list-item#agenda:hover{
  color: cyan;
}
.menu-list-item#presse:hover{
  color: red;
}
#la-cavale .menu-list-item#la-cavale{
  color: rgb(122,247,30);
}

#creations .menu-list-item#creations{
  color: rgb(255,0,204);
}

.page.creation .menu-list-item#creations{
  color: rgb(255,0,204);
}

#laboratoire .menu-list-item#laboratoire{
  color: blue;
}

.page.laboratoire .menu-list-item#laboratoire{
  color: blue;
}

.page.agenda .menu-list-item#agenda{
  color: cyan;
}

.agenda-dates .menu-list-item#agenda{
  color: cyan;
}
#presse .menu-list-item#presse{
  color: red;
}
.presse-projet .menu-list-item#presse{
  color: red;
}



.menu-infos{
  margin-top: 70px;
}

.facebook, .instagram{
  margin-top: 10px;
  width: 30px;
  height: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  a{
    display: block;
  }
}

.facebook{
  background-image: url("../img/facebook.svg");
  background-size: 50% auto;
}

.instagram{
  background-image: url("../img/instagram.svg");
}

.menu-list{
  a{
    display: block;
  }
}