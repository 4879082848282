.presse .img-overlay{
  background-color: red;
}

.presse-projet{
  .red{
    color: #ff0000;
  }
  .press{
    h4{
      padding-top: 20px;
    }
    a{
      display: block;
    }
  }
}

