.menu-xs{
  display: none;
  position:fixed;
  height: 100vh;
  width: 100%;
  z-index: 999999;
  background-color: white;
  opacity: 0;
  padding: 0 20px;
  left: 0px;
  transition: 0.2s ease-in-out;
  pointer-events: none;

  .menu-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    top:0;
    left: 0;
    padding: 20px;
    width: 100%;
    height: 100%;
  }

  #close{
    position: absolute;
    right:20px;
    cursor: pointer;
    z-index: 999999;
  }
  #close-cross{
    height: 24px;
    width: 24px;
    cursor: pointer;
  }

  .menu-list{
    max-width: 100%;
    display: block !important;
    h1{
      font-size: 25px;
    }
  }
}

#open{
  position: fixed;
  display: none;
  right:20px;
  top: 20px;
  cursor: pointer;
  z-index: 9999;
  .open-cross{
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
}