// RESET


input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

p,h1,h2,h3,h4,h5,h6{
  margin:0;
}

ul{
  list-style-type: none;
  padding: 0;
}

a{
  color: black;
}

a:hover{
  color: black;
}

body, .page, main{
  height:100%;
  width: 100%;
  padding:0;
  color: black;
  background-color: none;
}

.page{

  margin: 0 auto;
  min-width: 769px;
  max-width: 1200px;
}

main{
  padding-left: $headerwidth;
  padding-right: 50px;
  max-width: 100%;
  width: 100%;
}

h4 a:hover{
  text-decoration: none;
  color: inherit;
}

h3 a:hover{
  text-decoration: none;
  color: inherit;
}